var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notify-wishes container"},[_c('div',{staticClass:"wishes-body image-frame"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('wishes marked as seen'),expression:"'wishes marked as seen'",modifiers:{"hover":true}}],staticClass:"cursor-pointer floating-close",attrs:{"icon":"XIcon","size":"20"},on:{"click":function($event){return _vm.viewAllOrClosePopup(_vm.wishes, 'close')}}}),_c('div',{staticClass:"wishes-pop"},[_c('div',{class:['envelope', _vm.envelopeClass]},[_c('div',{staticClass:"front",on:{"click":_vm.toggleEnvelope}},[_vm._m(0)]),_c('div',{staticClass:"back"},[_c('div',{staticClass:"letter shadow-none"},[_c('div',{staticClass:"message-container"},[_c('p',{staticClass:"wishes-text mb-2 mt-2",domProps:{"innerHTML":_vm._s(_vm.currentWish.wish_message)}})]),_vm._m(1),_c('div',{staticClass:"wishes-icon d-flex align-items-end justify-content-end flex-column px-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{attrs:{"src":_vm.currentWish.wisher.avatar,"alt":"Wisher Avatar","width":"30px","height":"30px","rounded":"circle"}}),_c('div',{staticClass:"text-left ml-50"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.currentWish.wisher.name)+" ")]),_c('small',[_vm._v(_vm._s(_vm.currentWish.wisher.designation))])])]),_c('div',{staticClass:"w-100 cmt-posts d-flex"},[_c('div',{staticClass:"w-100 border commentBox main-post"},[_c('CommentBox',{ref:"commentBox",attrs:{"show":_vm.show,"value":_vm.show ? _vm.mentionHtml : '',"hide-image":true,"placeholder":"Reply","className":"comment-box","toolbar":false,"width":"100%","mentionList":_vm.employeeListOption,"id":'comment-fields-' +
                                            _vm.currentWishIndex},on:{"update:value":_vm.handleCommentUpdate}}),(_vm.show)?_c('b-button',{staticClass:"blog-btn-block search-post m-0",attrs:{"variant":"primary","pill":""},on:{"click":function($event){return _vm.addReply(
                                                _vm.currentWish,
                                                _vm.currentWishIndex
                                            )}}},[_vm._v(" Comment ")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between view-count w-100"},[(
                                            _vm.isHideCover && _vm.wishes.length > 0
                                        )?_c('span',[_vm._v(_vm._s(_vm.currentWishIndex + 1)+"/"+_vm._s(_vm.wishes[0].wish_count))]):_vm._e(),(
                                            _vm.isHideCover && _vm.wishes.length > 1
                                        )?_c('b-button',{staticClass:"view-all-btn float-end",attrs:{"variant":"outline-white"},on:{"click":function($event){return _vm.openPost(_vm.currentWish)}}},[_vm._v("View All")]):_vm._e()],1)])])]),_c('div',{staticClass:"flip left-flip"}),_c('div',{staticClass:"flip right-flip"}),_c('div',{staticClass:"flip bottom-flip"}),_c('div',{staticClass:"flip top-flip"})])]),_c('div',{staticClass:"d-flex align-items-center show-nextcard"},[(_vm.isEnvelopeOpen && _vm.wishes.length > 1)?_c('button',{staticClass:"left arrow-btn",attrs:{"disabled":_vm.currentWishIndex === 0},on:{"click":_vm.prevWish}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ChevronLeftIcon","size":"20","stroke":"#344054"}})],1):_vm._e(),(_vm.isEnvelopeOpen && _vm.wishes.length > 1)?_c('button',{staticClass:"right arrow-btn",attrs:{"disabled":_vm.currentWishIndex === _vm.wishes.length - 1},on:{"click":_vm.nextWish}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ChevronRightIcon","size":"20","stroke":"#344054"}})],1):_vm._e()])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-wishes mail"},[_c('h6',{staticClass:"wishes-intro"},[_vm._v(" You've got wishes from your colleagues! ")]),_c('h4',{staticClass:"tap-reveal"},[_vm._v("Tap to Reveal")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"cake"},[_c('div',{staticClass:"candle"},[_c('div',{staticClass:"fire"})])])])}]

export { render, staticRenderFns }