import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";

import { abilitiesPlugin } from "@casl/vue";
import ability from "@/libs/acl/ability";

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.use(abilitiesPlugin, ability);

import { BSpinner } from 'bootstrap-vue'
Vue.component('b-spinner', BSpinner)