import { AbilityBuilder, Ability } from '@casl/ability'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = Vue.$cookies.get('userData')
const existingAbility = userData ? userData.ability : null

// export default new Ability(existingAbility || initialAbility)

const { can, cannot, build } = new AbilityBuilder(Ability)

can('Profile', 'all');
can('Auth', 'all');
can('Public', 'all');

export default build()