import { $themeBreakpoints } from "@themeConfig";
import axios from "@axios";

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false
    },
    getters: {
        currentBreakPoint: state => {
            const { windowWidth } = state;
            if (windowWidth >= $themeBreakpoints.xl) return "xl";
            if (windowWidth >= $themeBreakpoints.lg) return "lg";
            if (windowWidth >= $themeBreakpoints.md) return "md";
            if (windowWidth >= $themeBreakpoints.sm) return "sm";
            return "xs";
        }
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val;
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay =
                val !== undefined ? val : !state.shallShowOverlay;
        }
    },
    actions: {
        fetchInterviewers() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/employees")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchEmployeeList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/employees", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchManagerList() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/managers")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchAllTeamsList() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/teams")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchCompanyTreeList() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/company_tree")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchCompanyTree() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/company_tree/view")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchCompanyTreeNodesList() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/company-tree")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchTeamList() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/my-teams")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        descendants() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/descendants")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        descendantMangers() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/descendantMangers")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchTeamMates(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/teammates", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchSessionList() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/sessions")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchCandidates() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/candidates")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchDesignationsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/designations", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchVacanciesList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/vacancies", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addInterview(ctx, interviewData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/interviews", interviewData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                            else {
                                reject(error.response.data.message)
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        update(ctx, formData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(formData.url, formData.params, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                            else {
                                reject(error.response.data.message)
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        create(ctx, formData) {
            return new Promise((resolve, reject) => {
                axios
                    .get(formData.url)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        store(ctx, formData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(formData.url, formData.params, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 422) {
                            const { errors, message } = error.response.data;
                            if (errors) {
                                const errorKeys = Object.keys(errors);
                                if (errorKeys.length > 0) {
                                    const firstErrorKey = errorKeys[0];
                                    reject(errors[firstErrorKey][0]);
                                }
                            }
                            if (message) {
                                reject(message);
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        storeNoHeader(ctx, formData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(formData.url, formData.params)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        get(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(queryParams.url, queryParams)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error =>
                        reject(error));
            });
        },
        destroy(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(queryParams.url + "/" + queryParams.hashid)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        delete(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(queryParams.url)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
};
