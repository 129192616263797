import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import ability from "@/libs/acl/ability";
import axios from "@axios";
import {
    isUserLoggedIn,
    getUserData,
    getHomeRouteForLoggedInUser
} from "@/auth/utils";
import pages from "./routes/pages";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            name: "home",
            // component: () => import("@/views/feed/Profile.vue"),
            component: () => import("@/views/feed/Community.vue"),
            meta: {
                pageTitle: "Home",
                resource: "Home",
                gate: "Profile",
                breadcrumb: [
                    {
                        text: "Home",
                        active: true
                    }
                ]
            }
        },
        {
            path: "/testing-new-component",
            name: "testing",
            // component: () => import("@/views/feed/Profile.vue"),
            component: () => import("@/views/components.vue"),
            meta: {
                pageTitle: "Home",
                resource: "Home",
                gate: "Profile",
                breadcrumb: [
                    {
                        text: "Home",
                        active: true
                    }
                ]
            }
        },
        {
            path: "/community?filter=scheduled-posts",
            name: "scheduled-posts",
            // component: () => import("@/views/feed/Profile.vue"),
            component: () => import("@/views/feed/Community.vue"),
            meta: {
                pageTitle: "Home",
                resource: "Home",
                gate: "Profile",
                breadcrumb: [
                    {
                        text: "Home",
                        active: true
                    }
                ]
            }
        },
        {
            path: "/community?filter=draft-posts",
            name: "draft-posts",
            // component: () => import("@/views/feed/Profile.vue"),
            component: () => import("@/views/feed/Community.vue"),
            meta: {
                pageTitle: "Home",
                resource: "Home",
                gate: "Profile",
                breadcrumb: [
                    {
                        text: "Home",
                        active: true
                    }
                ]
            }
        },
        {
            path: "/community?filter=saved-posts",
            name: "saved-posts",
            // component: () => import("@/views/feed/Profile.vue"),
            component: () => import("@/views/feed/Community.vue"),
            meta: {
                pageTitle: "Home",
                resource: "Home",
                gate: "Profile",
                breadcrumb: [
                    {
                        text: "Home",
                        active: true
                    }
                ]
            }
        },
        {
            path: "/community",
            name: "community",
            // component: () => import("@/views/feed/Profile.vue"),
            component: () => import("@/views/feed/Community.vue"),
            meta: {
                pageTitle: "Home",
                resource: "Home",
                gate: "Profile",
                breadcrumb: [
                    {
                        text: "Home",
                        active: true
                    }
                ]
            }
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: () => import("@/views/dashboard/Analytics.vue"),
            meta: {
                pageTitle: "Dashboard",
                resource: "Dashboard",
                gate: "Profile",
                breadcrumb: [
                    {
                        text: "Dashboard",
                        active: true
                    }
                ]
            }
        },
        ...pages,
        {
            path: "/login",
            name: "auth-login",
            component: () => import("@/views/Login.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
            
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import("@/views/ForgotPassword.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth",
                redirectIfLoggedIn: true
            }
        },
        {
            path: "/password/reset",
            name: "password-reset",
            component: () => import("@/views/ResetPassword.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth",
                redirectIfLoggedIn: true
            }
        },
        {
            path: "/password/generate",
            name: "password-generate",
            component: () => import("@/views/GeneratePassword.vue"),
            meta: {
                layout: "full",
                // resource: "Auth",
                // gate: "Auth",
                // redirectIfLoggedIn: false
            }
        },
        {
            path: "/error-404",
            name: "error-404",
            component: () => import("@/views/pages/miscellaneous/Error.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "/pages/miscellaneous/not-authorized",
            name: "misc-not-authorized",
            component: () =>
                import("@/views/pages/miscellaneous/NotAuthorized"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "*",
            redirect: "error-404"
        },
        {
            path: "/find-host",
            name: "find-host",
            component: () => import("@/views/FindTenantHost.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth",
                redirectIfLoggedIn: true
            }
        },
        {
            path: "/register",
            name: "register",
            component: () => import("@/views/registration/onboarding.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "/registration-completed",
            name: "registration-completed",
            component: () => import("@/views/registration/ComingSoon.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "/activate/:token",
            name: "activate",
            component: () => import("@/views/registration/Activate.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "/join",
            name: "join",
            component: () => import("@/views/JoinMeeting.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },

        {
            path: "/authorize/:provider/callback/mobile",
            name: "authorize-callback-mobile",
            component: () => import("@/views/SocialLoginMobile.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "/authorize/:provider/callback",
            name: "authorize-callback",
            component: () => import("@/views/SocialLogin.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "/onboarding/:email?",
            name: "onboarding",
            component: () => import("@/views/registration/onboarding.vue"),
            meta: {
                layout: "full",
                // resource: "Auth",
                // gate: "Auth"
            }
        },
        {
            path: "/register/:provider/callback/mobile",
            name: "register-callback-mobile",
            component: () => import("@/views/VerifyEmailRegisterMobile.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "/register/:provider/callback",
            name: "register-callback",
            component: () => import("@/views/VerifyEmailRegister.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
        {
            path: "/itsupportlogin",
            name: "itsupportlogin",
            component: () => import("@/views/DCKAPIT/Login.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
       
        {
            path: "/public-post/:token/:user_id?",
            name: "public-post",
            component: () => import("@/views/feed/PublicPost.vue"),
            meta: {
                layout: "full",
                resource: "Auth",
                gate: "Auth"
            }
        },
     
    ]
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();

    // axios
    // .get("abilities")
    // .then((response) => {
    //   if(response.status =='200')
    //   {
    //     this.$ability.update([
    //       { subject: "all", actions: response.data.data },
    //     ]);
    //   }
    //   if(response.status =='401')
    //   {
    //       this.$router.push({ name: 'auth-login' })
    //   }

    // })
    // .catch((error) => {
    //   console.log(error);
    // });

    var arr = [
        "misc-not-authorized",
        "auth-login",
        "register",
        "terms",
        "activate",
        "privacy-policy",
        "authorize-callback",
        "authorize",
        "registration-completed",
        "password-generate"
    ];

    if (!arr.includes(to.name)) {
        localStorage.setItem("pathToLoadAfterLogin", to.fullPath);
        localStorage.setItem("hasRedirect", false);
    }

    // if (!canNavigate(to)) {

    //     if(!arr.includes(to.name))
    //     {
    //         localStorage.setItem("pathToLoadAfterLogin", to.fullPath);
    //         localStorage.setItem("hasRedirect", false);
    //     }
    //     localStorage.setItem("hasRedirect", true);

    //     // Redirect to login if not logged in

    //     if (!isLoggedIn) {
    //         return next({ name: "auth-login" });
    //     }

    //     // return alert('You are not authorized!'+to.meta.gate)
    //     // If logged in => not authorized
    //     return next({ name: "misc-not-authorized" });
    // }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData();
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    }

    return next();
});

export default router;
