import axios from '@axios'
import JwtService from './jwtService'

function useJwt(axiosIns, jwtOverrideConfig) {
  const jwt = new JwtService(axiosIns, jwtOverrideConfig)

  return {
    jwt,
  }
}

const { jwt } = useJwt(axios, {
  loginEndpoint: '/login',
  registerEndpoint: '/register',
  refreshEndpoint: '/refresh-token',
  logoutEndpoint: '/logout',
  getUserInfoEndpoint: '/user',
  getSocialLoginEndpoint: '/auth/login/',
})
export default jwt
