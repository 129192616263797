<template>
    <div class="notify-wishes container">
        <div class="wishes-body image-frame">
            <feather-icon
                v-b-tooltip.hover="'wishes marked as seen'"
                class="cursor-pointer floating-close"
                icon="XIcon"
                size="20"
                @click="viewAllOrClosePopup(wishes, 'close')"
            />
            <div class="wishes-pop">
                <div :class="['envelope', envelopeClass]">
                    <div class="front" @click="toggleEnvelope">
                        <div class="send-wishes mail">
                            <h6 class="wishes-intro">
                                You've got wishes from your colleagues!
                            </h6>
                            <h4 class="tap-reveal">Tap to Reveal</h4>
                        </div>
                    </div>
                    <div class="back">
                        <div class="letter shadow-none">
                            <div class="message-container">
                                <p
                                    class="wishes-text mb-2 mt-2"
                                    v-html="currentWish.wish_message"
                                ></p>
                            </div>
                            <div class="box">
                                <div class="cake">
                                    <div class="candle">
                                        <div class="fire"></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="wishes-icon d-flex align-items-end justify-content-end flex-column px-2"
                            >
                                <div class="d-flex align-items-center">
                                    <img
                                        :src="currentWish.wisher.avatar"
                                        alt="Wisher Avatar"
                                        width="30px"
                                        height="30px"
                                        rounded="circle"
                                    />
                                    <div class="text-left ml-50">
                                        <h6 class="mb-0">
                                            {{ currentWish.wisher.name }}
                                        </h6>
                                        <small>{{
                                            currentWish.wisher.designation
                                        }}</small>
                                    </div>
                                </div>
                                <div class="w-100 cmt-posts d-flex">
                                    <div
                                        class="w-100 border commentBox main-post"
                                    >
                                        <CommentBox
                                            ref="commentBox"
                                            @update:value="handleCommentUpdate"
                                            :show="show"
                                            :value="show ? mentionHtml : ''"
                                            :hide-image="true"
                                            placeholder="Reply"
                                            className="comment-box"
                                            :toolbar="false"
                                            width="100%"
                                            :mentionList="employeeListOption"
                                            :id="
                                                'comment-fields-' +
                                                currentWishIndex
                                            "
                                        />
                                        <b-button
                                            variant="primary"
                                            v-if="show"
                                            class="blog-btn-block search-post m-0"
                                            pill
                                            @click="
                                                addReply(
                                                    currentWish,
                                                    currentWishIndex
                                                )
                                            "
                                        >
                                            Comment
                                        </b-button>
                                    </div>
                                    <div
                                        class="d-flex align-items-center justify-content-between view-count w-100"
                                    >
                                        <span
                                            v-if="
                                                isHideCover && wishes.length > 0
                                            "
                                            >{{ currentWishIndex + 1 }}/{{
                                                wishes[0].wish_count
                                            }}</span
                                        >
                                        <b-button
                                            v-if="
                                                isHideCover && wishes.length > 1
                                            "
                                            class="view-all-btn float-end"
                                            @click="openPost(currentWish)"
                                            variant="outline-white"
                                            >View All</b-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flip left-flip"></div>
                        <div class="flip right-flip"></div>
                        <div class="flip bottom-flip"></div>
                        <div class="flip top-flip"></div>
                    </div>
                </div>
                <div class="d-flex align-items-center show-nextcard">
                    <button
                        v-if="isEnvelopeOpen && wishes.length > 1"
                        class="left arrow-btn"
                        @click="prevWish"
                        :disabled="currentWishIndex === 0"
                    >
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="20"
                            class="cursor-pointer"
                            stroke="#344054"
                        />
                    </button>
                    <button
                        v-if="isEnvelopeOpen && wishes.length > 1"
                        class="right arrow-btn"
                        @click="nextWish"
                        :disabled="currentWishIndex === wishes.length - 1"
                    >
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="20"
                            class="cursor-pointer"
                            stroke="#344054"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@axios";
import Vue from "vue";
import CommentBox from "@/components/CommentBox.vue";
import store from "@/store";
import $ from "jquery";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
    BEmbed,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BImg,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
} from "bootstrap-vue";
import { bus } from "@/main.js";

export default {
    components: {
        CommentBox,
        ToastificationContent,
    },
    props: {
        wishes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isEnvelopeOpen: false,
            currentWishIndex: 0,
            currentCelebrationIndex: 0,
            seenWishIds: [],
            replyMessage: "",
            show: "",
            isHideCover: false,
            employeeListOptions: this.$cookies.get("employeeListOptions"),
        };
    },
    computed: {
        currentWish() {
            return this.wishes[this.currentWishIndex] || {}; // Safeguard against undefined
        },
        employeeListOption() {
            return this.employeeListOptions.filter(
                (employee) => employee.id === this.currentWish.wisher.hashid
            );
        },
        envelopeClass() {
            if (this.isEnvelopeOpen) {
                setTimeout(() => {
                    this.isHideCover = true;
                }, 3000);
                return this.isHideCover ? "open hide-cover" : "open";
            } else {
                this.isHideCover = false;
                return "new";
            }
        },
        mentionHtml() {
            if (!this.currentWish.wisher) return "";
            return `<span class="mention" 
      data-index=${this.currentWishIndex}
      data-denotation-char="@" 
      data-value="<a href=&quot;/profile/${this.currentWish.wisher.hashid}&quot; target=_blank>${this.currentWish.wisher.name}</a>" 
      data-id="${this.currentWish.wisher.hashid}" 
      data-hover="${this.currentWish.wisher.name} [${this.currentWish.wisher.email}]" 
      data-target="_blank" 
      data-link="/profile/${this.currentWish.wisher.hashid}">
    <span contenteditable="false">
        <span class="ql-mention-denotation-char">@</span><a href="/profile/${this.currentWish.wisher.hashid}" target="_blank">${this.currentWish.wisher.name}</a>
    </span>
</span>`;
        },
    },
    mounted() {
        this.setCursorToEnd(); // Set cursor position when mounted
    },

    methods: {
        openPost(wish) {
            this.viewAllOrClosePopup([wish], "close");
            return window.open(
                "?post_hash_id=" + wish.celebration.post.hashid,
                "_blank"
            );
        },
        handleCommentUpdate(val) {
            this.show = val;
            this.setCursorToEnd();
        },
        setCursorToEnd() {
            this.$nextTick(() => {
                const editorElement =
                    this.$refs.commentBox.$el.querySelector(".ql-editor");
                if (editorElement) {
                    editorElement.focus();
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(editorElement);
                    range.collapse(false);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            });
        },
        toggleEnvelope() {
            this.isEnvelopeOpen = !this.isEnvelopeOpen;
        },
        prevWish() {
            if (this.currentWishIndex > 0) {
                this.currentWishIndex--;
            }
        },
        nextWish() {
            if (this.currentWishIndex < this.wishes.length - 1) {
                if (!this.seenWishIds.includes(this.currentWish.hashid)) {
                    this.seenWishIds.push(this.currentWish.hashid);
                }
                this.currentWishIndex++;
                if (this.currentWishIndex === this.wishes.length - 1) {
                    if (!this.seenWishIds.includes(this.currentWish.hashid)) {
                        this.seenWishIds.push(this.currentWish.hashid);
                        this.updateUserSeen();
                    }
                }
            }
        },
        updateUserSeen() {
            let wish_id = this.seenWishIds.slice();
            axios
                .put("/wish/update", { wish_id })
                .then((response) => {
                    console.log(response.data.message);
                })
                .catch((error) => {
                    console.error("Error updating the wish:", error);
                });
        },
        viewAllOrClosePopup(celebrateData, type) {
            let celebrateIds = celebrateData.map(
                (celebration) => celebration.celebration.hashid
            );
            if (type == "view") {
                axios
                    .put("/wish/update-all", { celebrateIds })
                    .then((response) => {
                        if (response.data.success) {
                            if (this.$route.name !== "home") {
                                this.$router.push({
                                    path: `/?post_hash_id=${celebrateData[0].celebration.post.hashid}`,
                                });
                            }
                            bus.$emit("celebrate-check", false);
                            bus.$emit(
                                "view-post",
                                celebrateData[0].celebration.post
                            );

                            // celebrateData.forEach((item) => {
                            //     if (item.celebration && item.celebration.post) {
                            //         bus.$emit(
                            //             "view-post",
                            //             item.celebration.post
                            //         );
                            //     }
                            // });
                        }
                    })
                    .catch((error) => {
                        console.error("Error updating the wish:", error);
                    });
            } else {
                axios
                    .put("/wish/update-all", { celebrateIds })
                    .then((response) => {
                        if (response && celebrateData.length > 1) {
                            bus.$emit("celebrate-check", false);
                        }
                    })
                    .catch((error) => {
                        console.error("Error updating the wish:", error);
                    });
            }
        },
        addReply(data, index) {
            var comment = document.getElementById(
                `comment-fields-${index}`
            ).value;
            if (comment.length > 505) {
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: "Reply wish adding Failed",
                            icon: "BellIcon",
                            variant: "danger",
                            hideClose: true,
                            text: "The message may not be greater than 110 characters",
                        },
                    },
                    {
                        timeout: 2000,
                        position: "bottom-center",
                        toastClassName: "radius-8 error-info",
                        hideProgressBar: true,
                    }
                );
                return false;
            }

            if (comment === "") {
                this.$toast(
                    {
                        component: ToastificationContent,
                        props: {
                            title: "Reply wish adding Failed",
                            icon: "BellIcon",
                            variant: "danger",
                            hideClose: true,
                            text: "Can't post empty reply wish",
                        },
                    },
                    {
                        timeout: 2000,
                        position: "bottom-center",
                        toastClassName: "radius-8 error-info",
                        hideProgressBar: true,
                    }
                );
                return false;
            }
            let params = {};

            params = {
                comment: comment,
                parent_comment_id: data.comment.hashid,
            };
            this.$http
                .post(`/posts/${data.celebration.post.hashid}/comments`, params)
                .then((res) => {
                    document.getElementById(`comment-fields-${index}`).value =
                        "";
                    $(".ql-editor").html("");

                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "wish send successfully",
                                icon: "BellIcon",
                                variant: "success",
                                text: "wish send",
                            },
                        },
                        {
                            timeout: 2000,
                            position: "bottom-center",
                            toastClassName: "radius-8 successful-info",
                            hideProgressBar: true,
                        }
                    );
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 422) {
                        if (typeof error.response.data.errors !== "undefined") {
                            const keys = Object.keys(
                                error.response.data.errors
                            );
                            const values = Object.values(
                                error.response.data.errors
                            );
                            for (let i = 0; i <= keys.length; i += 1) {
                                if (
                                    error.response.data.errors.hasOwnProperty.call(
                                        values,
                                        i
                                    )
                                ) {
                                    this.$toast(
                                        {
                                            component: ToastificationContent,
                                            props: {
                                                title: "Comment adding Failed",
                                                icon: "BellIcon",
                                                variant: "danger",
                                                hideClose: true,
                                                text: values[0][0],
                                            },
                                        },
                                        {
                                            timeout: 2000,
                                            position: "bottom-center",
                                            toastClassName:
                                                "radius-8 error-info",
                                            hideProgressBar: true,
                                        }
                                    );
                                }
                            }
                        }
                    } else {
                        // this.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Comment adding Failed",
                        //         icon: "BellIcon",
                        //         text: "Oops! Something Went Wrong",
                        //         variant: "danger",
                        //     },
                        // });

                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                    title: "Comment adding Failed",
                                    icon: "BellIcon",
                                    variant: "danger",
                                    hideClose: true,
                                    text: "Oops! Something Went Wrong",
                                },
                            },
                            {
                                timeout: 2000,
                                position: "bottom-center",
                                toastClassName: "radius-8 error-info",
                                hideProgressBar: true,
                            }
                        );
                    }
                });
            return true;
        },
    },
};
</script>
