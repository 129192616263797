import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: localStorage.getItem('is-collapsed') || $themeConfig.layout.menu.isCollapsed ,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, value) {
      state.isVerticalMenuCollapsed = value;
      localStorage.setItem('is-collapsed',value)

    },
  },
  actions: {},
}
